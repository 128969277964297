import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { FtTechrecruitmentComponent } from './views/ft-techrecruitment/ft-techrecruitment.component';
import { ContactViewComponent } from './views/contact-view/contact-view.component';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';




const routes: Routes = [
  { path: "", redirectTo: "accueil",pathMatch: "full" },
  { path: "recrutement_exceptionnel", component: FtTechrecruitmentComponent },
  { path: "contacter-nous", component: ContactViewComponent },
  { path: "site_en_maintenance", component: MaintenanceComponent },
  { path: "accueil", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
