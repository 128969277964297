<div *ngIf="item.isActive" class="card-service" (click)="goToNext()">
    <div class="img-box mb-1">
        <img *ngIf="item.imgUrl" [src]="item.imgUrl" alt="item.imgUrl" srcset="">
    </div>
    <i *ngIf="item.icon" [class]="item.icon" class="mb-1"></i>
    <h3 class="mb-1">{{item.title}}</h3>
</div>

<p-dialog  [(visible)]="modale.isVisible" [style]="{width: '30vw'}"
    [breakpoints]="{ '768px': '100vw' }">
    <h1>{{modale.title}}</h1>
    <div class="center">
        <app-button class="mt-4 mb-2" [params]="button" (click)="closeModale()" width="100%"/>
    </div>
</p-dialog>