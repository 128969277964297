<div class="container">
    <span class="big-circle"></span>
    <img src="../../../../assets/shape.png" class="square" alt="" />
    <div class="form">
      <div class="contact-info">
        <h3 class="title">Let's get in touch</h3>
        <p class="text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
          dolorum adipisci recusandae praesentium dicta!
        </p>

        <div class="info">
          <div class="information">
            <i class="pi pi-map-marker"></i>
            <p>Plateau, Cité Administrative, Tour B 5e, 6e,7e Etages</p>
          </div>
          <div class="information">
            <i class="pi pi-at"></i>
            <p>lorem@ipsum.com</p>
          </div>
          <div class="information">
            <i class="pi pi-phone"></i>
            <p>(225) 27 20 27 85 33</p>
          </div>
        </div>

        <div class="social-media">
          <p>Suivez-nous :</p>
          <div class="social-icons">
            <a href="http://www.facebook.com/FormationProCi">
              <i class="pi pi-facebook"></i>
            </a>
            <a href="https://twitter.com/metfpa">
              <i class="pi pi-twitter"></i>
            </a>
            <a href="#">
              <i class="pi pi-youtube"></i>
            </a>
            <a href="https://www.linkedin.com/in/metfpa/">
              <i class="pi pi-linkedin"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="contact-form">
        <span class="circle one"></span>
        <span class="circle two"></span>

        <form action="index.html" autocomplete="off">
          <h3 class="title">Laissez-nous un message</h3>
          <div class="input-container">
            <input (focus)="onFocus($event)" (blur)="onBlur($event)" type="text" name="name" class="input " />
            <label for="">Nom complet</label>
            <span>Nom complet</span>
          </div>
          <div class="input-container">
            <input (focus)="onFocus($event)" (blur)="onBlur($event)" type="email" name="email" class="input" />
            <label for="">Adresse email</label>
            <span>Adresse email</span>
          </div>
          <div class="input-container">
            <input (focus)="onFocus($event)" (blur)="onBlur($event)" type="tel" name="phone" class="input" />
            <label for="">Object</label>
            <span>Object</span>
          </div>
          <div class="input-container">
            <input (focus)="onFocus($event)" (blur)="onBlur($event)" type="tel" name="phone" class="input" />
            <label for="">Numéro de téléphone</label>
            <span>Numéro de téléphone</span>
          </div>
          <div class="input-container textarea">
            <textarea (focus)="onFocus($event)" (blur)="onBlur($event)" name="message" class="input"></textarea>
            <label for="">Message</label>
            <span>Message</span>
          </div>
          <input type="submit" value="Send" class="btn" />
        </form>
      </div>
    </div>
  </div>