import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutlineButtonComponent } from './components/outline-button/outline-button.component';
import { ButtonComponent } from './components/button/button.component';
import { LogoErsysComponent } from './components/logo-ersys/logo-ersys.component';
import { CarouselModule } from 'primeng/carousel';
import { CardServiceComponent } from './components/card-service/card-service.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContactComponent } from './components/contact/contact.component';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { TitleComponent } from './components/title/title.component';
import { DialogModule } from 'primeng/dialog';
import { InfoComponent } from './components/info/info.component';





@NgModule({
  declarations: [
    OutlineButtonComponent,
    ButtonComponent,
    LogoErsysComponent,
    CardServiceComponent,
    FooterComponent,
    CarouselComponent,
    NavbarComponent,
    ContactComponent,
    TitleComponent,
    InfoComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    DialogModule
  ],
  exports: [
    OutlineButtonComponent,
    ButtonComponent,
    LogoErsysComponent,
    CarouselModule,
    CardServiceComponent,
    FooterComponent,
    CarouselComponent,
    NavbarComponent,
    ContactComponent,
    TimelineModule,
    CardModule,
    TitleComponent,
    DialogModule,  
    InfoComponent
  ]
})
export class SharedModule { }
