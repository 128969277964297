import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-ersys',
  templateUrl: './logo-ersys.component.html',
  styleUrls: ['./logo-ersys.component.css']
})
export class LogoErsysComponent {

}
