import { Component } from '@angular/core';
import { EventItem, ICustomButton } from 'src/app/utils/types';

@Component({
  selector: 'app-ft-techrecruitment',
  templateUrl: './ft-techrecruitment.component.html',
  styleUrls: ['./ft-techrecruitment.component.css']
})
export class FtTechrecruitmentComponent {
  button:ICustomButton = {
    tilte:"DEMARRER MON INSCRIPTION",
    iconRight:"pi pi-arrow-circle-right"
  }
  orange:string = "#1C2E82"
  events: EventItem[] = [
    { title: '1 - IDENTIFICATION', description: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: this.orange },
    { title: "2 - VERIFICATION DE L'OTP", description: '15/10/2020 14:00', icon: 'pi pi-cog', color: this.orange },
    { title: '3 - INFORMATIONs DU CANDIDANT', description: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: this.orange },
    { title: "4 - ESPACE ADMINITRATION", description: '16/10/2020 10:00', icon: 'pi pi-check', color: this.orange }
  ];
}
