<div class="container">
    <header>
        <app-navbar />
        <app-carousel />
    </header>
    <app-info />
    <app-title class="mt-2" title="Services en ligne" />
    <section class="container-fluid mb-5">
        <div class="grid-4">
            <app-card-service *ngFor="let item of items" [item]="item" />
        </div>
    </section>
</div>
<app-footer />