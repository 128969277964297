
<div class="sidebar" [ngClass]="{'close':!isVisibleMenu}">
    <app-logo-ersys />
    <a *ngFor="let item of itemList" (click)="navigateByUrl(item)">
        <i [class]="item.icon"></i>
        <h3>{{item.title}}</h3>
    </a>
</div>
<nav>
    <app-logo-ersys />
    <ul>
        <li *ngFor="let item of itemList" (click)="navigateByUrl(item)"><a>{{item.title}}</a></li>
    </ul>  
    <i (click)="alternateMenu()" [class]="isVisibleMenu ? 'pi pi-times' : 'pi pi-th-large'"></i>
</nav>