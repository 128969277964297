import { Component, EventEmitter, Input, Output } from '@angular/core';
import { C_Loader } from 'src/app/utils/constant';
import { ICustomButton } from 'src/app/utils/types';

@Component({
  selector: 'app-outline-button',
  templateUrl: './outline-button.component.html',
  styleUrls: ['./outline-button.component.css']
})
export class OutlineButtonComponent {
  
  loaderImg = C_Loader
  @Input() params: ICustomButton = { tilte: "button" }
  @Output() click: EventEmitter<void> = new EventEmitter()

  onClick(): void {
    this.click.emit()
  }
}
