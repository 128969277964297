<p-carousel [value]="items" [numVisible]="1" [numScroll]="1" [showIndicators]="false" [showNavigators]="false"
    [autoplayInterval]="7000" [responsiveOptions]="responsiveOptions">
    <ng-template let-item pTemplate="item">
        <div class="box-carousel">
            <div class="bg-carousel"></div>
            <img [src]="item.imgUrl" [alt]="item.title">
            <div class="card-carousel">
                <h1 class="mb-1 ">{{item.title}}</h1>
                <p class="mb-2">{{item.description}}</p>
                <div class="center">
                    <button (click)="goToNext(item.link)" type="button"
                        class="btn btn-orange ">{{item.btnTitle}}</button>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>