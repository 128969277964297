import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ICarouselPatial, ICustomButton, IModal } from 'src/app/utils/types';



@Component({
  selector: 'app-card-service',
  templateUrl: './card-service.component.html',
  styleUrls: ['./card-service.component.css']
})
export class CardServiceComponent {

  constructor(private readonly router: Router) { }

  @Input() item: ICarouselPatial = {} as ICarouselPatial
  modale: IModal = {} as IModal
  button:ICustomButton = {
    tilte:"Fermer",
    iconLeft:"pi pi-times"
  }

  goToNext(): void {
    if (this.item.link) {
      window.open(this.item.link, "_self")
      return
    }
    this.openModale()
  }

  openModale(): void {
    this.modale.isVisible = true
    this.modale.title = "Service momentanément indisponible"
  }
  closeModale(): void {
    this.modale.isVisible = false
    this.modale.title = ""
  }

}
