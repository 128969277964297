import { Component, EventEmitter, Input, Output } from '@angular/core';
import { C_Loader } from 'src/app/utils/constant';
import { ICustomButton } from 'src/app/utils/types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {
  loaderImg = C_Loader
  @Input() width:string = "50%"
  @Input() params: ICustomButton = { tilte: "button"}
  @Output() click: EventEmitter<void> = new EventEmitter()

  onClick(): void {
    this.click.emit()
  }
}
