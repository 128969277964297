import { Component } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  onFocus(event: any) {
    const parent = event.target.parentNode;
    parent.classList.add('focus');
  }

  onBlur(event: any) {
    const parent = event.target.parentNode;
    if (event.target.value === '') {
      parent.classList.remove('focus');
    }
  }
}
