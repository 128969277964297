import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICarouselPatial } from 'src/app/utils/types';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {

  constructor(private readonly router: Router){}
  baseUrl = "../../../../assets"
//https://bts.ersys-ci.net
// btnTitle: "je m'inscris"
  items: ICarouselPatial[] = [
    { link: "https://chgets.ersys-ci.net", isActive: true, imgUrl: `${this.baseUrl}/IMG_1261.jpg`, btnTitle: "je m'inscris", title: "Demande de transfert 2024-2025", description: "Formuler votre demande de transfert directement en ligne." },
    //{ link: "https://insligne.ersys-ci.net", isActive: true, imgUrl: `${this.baseUrl}/etudiant5.jpg`, btnTitle: "je m'inscris", title: "Ouverture des inscriptions en ligne 2023 - 2024 (uniquement les anciens)", description: "Procéder à votre inscription en ligne pour accéder aux établissements publics et privés de l'ETFP." },
    { link: "#", isActive: true, imgUrl: `${this.baseUrl}/Mme Souame 17 mai 2023-10.jpg`, title: "Ouverture des demandes post-orientation pour les élèves en 3ème", description: "Formuler votre demande de réorientation, réaffectation ou une nouvelle demande d'orientation à l'ETFP." },
    { link: "#", isActive: true, imgUrl: `${this.baseUrl}/IMG_1261.jpg`, title: "Demande d'orientation en 1 ère année BTS session 2023", description: "Formuler votre demande d'orientation dans les établissements publics de l'ETFP." },
    //{ link: "https://oat.ersys-ci.net/", isActive: true, imgUrl: `${this.baseUrl}/etudiant5.jpg`, btnTitle: "je m'inscris", title: "Demande d'orientation session 2024", description: "Formuler votre demande d'orientation dans les établissements publics et privés de l'ETFP." },
    { link: "http://resultatoat.ersys-ci.net", isActive: true, imgUrl: `${this.baseUrl}/MmeSouame17mai20236.jpg`, btnTitle: "je consulte", title: "Resultats demande d'orientation (CNSA + POST) session 2024", description: "Vous avez effectué une demande d'orientation à l'ETFPA. Cliquer ici pour consulter votre résultat." },
  ]

  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ]

  goToNext(link: string): void {
    window.open(link,"_self");
    // this.router.navigateByUrl('')
  }

}
