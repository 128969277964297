import { Component, OnInit } from '@angular/core';
import { ICarouselPatial } from 'src/app/utils/types';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  ngOnInit(): void {
    const baseUrl = "../../../../assets"
    this.items = [
      { link: "https://chgets.ersys-ci.net", imgUrl: `${baseUrl}/changeschool.png`, isActive: true, title: "Demande de transfert 2024-2025", },
      { link: "https://onlinereg.ersys-ci.net/", imgUrl: `${baseUrl}/registration.png`, isActive: true, title: "Inscription en ligne 2024 - 2025", },
      //{ link: "https://oat.ersys-ci.net/", imgUrl: `${baseUrl}/oat.png`, isActive: true, title: "Collecte des voeux d'orientations session 2024", },
      //{ link: "https://concours.ersys-ci.net/", imgUrl: `${baseUrl}/competition.png`, isActive: true, btnTitle: "je fais ma demande", title: "Concours d'entrée à l'ETFP session 2024" },
      { link: "http://resultatoat.ersys-ci.net", imgUrl: `${baseUrl}/results.png`, isActive: true, title: "Résultats demande d'orientation à l'ETFP (2nde Technique, BT, BTS, Passerelle CAP) session 2024", },
      
      { link: "https://candidatlibre.ersys-ci.net", imgUrl: `${baseUrl}/free_candidate.png`, isActive: true, title: "Inscription candidats libre session 2025", },
      
      //{ link: "https://postorientation.ersys-ci.net/", imgUrl: `${baseUrl}/orientation.png`, isActive: true, title: "Réorientations, Réaffectations et nouvelles demandes d'orientation pour les élèves de troisième (Post-Orientation)",  },
      //{ link: "https://bts.ersys-ci.net", imgUrl: `${baseUrl}/certificate.png`, isActive: true, title: "Demande d'orientation en 1 ère annnée BTS sessions 2024", },
      //{ link: "https://passerelle.ersys-ci.net", imgUrl: `${baseUrl}/passerelle.png`, isActive: true, btnTitle: "je m'inscris", title: "Demande d'orientation par voie de passerelle session 2024" },
      //{ link: "https://www.dexcci.net/dexc/resultatsconcours.php", imgUrl: `${baseUrl}/free_candidate.png`, isActive: true, btnTitle: "Convocation", title: "Résultats des Concours Scolaires session 2024." },
     
      { link: "", isActive: true, },
      { link: "", isActive: true, },
      { link: "", isActive: true, },
      { link: "", isActive: true, },
      //{ link: "", isActive: true, },
      //{ link: "", isActive: true, },
      //{ link: "", isActive: true, },
    ]
    this.items = this.items.filter(item => item.isActive === true)
  }
  items: ICarouselPatial[] = []
}
