export const C_Loader = "../../../../assets/loader.gif"
export const C_Url = "../../../../assets/person.png"
export const C_Files = "Files"
export const C_Save = "post_orientation_request"
export const C_ClientBaseUrl = "http://147.182.139.206:3014/"
export const MATRICULE_REGEX = /^[0-9]{8}[A-Z]{1}/
export const OTP_REGEX = /^[0-9]{4}/

export enum TYPE_OF_SCHOOL {
    public = 2,
    private = 1
}
