import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { INavBar } from 'src/app/utils/types';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  constructor(private readonly router: Router) { }

  isVisibleMenu = false
  itemList: INavBar[] = [
    // { title: "Contactez-nous", url: "contacter-nous", type: "in", icon: "pi pi-phone " },
    { title: "Espace administrateur", url: "http://admin.ersys-ci.net", type: "out", icon: "pi pi-cog" }
  ]

  alternateMenu() {
    this.isVisibleMenu = !this.isVisibleMenu
  }

  navigateByUrl({ type, url }: INavBar): void {
    this.alternateMenu()
    switch (type) {
      case "in":
        this.router.navigateByUrl(url)
        break;
      case "out":
        window.open(url)
        break;

      default:
        break;
    }
  }
}
