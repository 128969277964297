<div class="container">
    <app-title title="Processus d'inscription du recrutement exceptionnel" />
    <div class="container-fluid">
        <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
            <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-2" [style.backgroundColor]="event.color">
                    <i [ngClass]="event.icon"></i>
                </span>
            </ng-template>
            <ng-template pTemplate="content" let-event>
                <p-card [header]="event.title">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error
                        repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione
                        quam perferendis esse, cupiditate
                        neque quas!
                    </p>
                </p-card>
            </ng-template>
        </p-timeline>
    </div>
    <app-button class="mt-4 mb-2" [params]="button" width="100%"/>
</div>
<app-footer />